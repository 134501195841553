import { EModuleCode } from "src/app/shared/enums/modules.enum";

export const environment = {
  BASE_URL: "https://api.weattend-dev.arkleap-tech.com/api/",
  ASSET_URL: "https://api.weattend-dev.arkleap-tech.com",
  ASSET: "../../../assets/i18n/",
  LOGO: "../../../assets/img/logo/we_logo.svg",
  ICON: "./assets/img/logo/we_tab_logo.svg",
  TITLE: "WeAttend",
  production: false,
  ENABLE_FEATURE: {
    [EModuleCode.AttendanceManagement]: true,
    [EModuleCode.CompanySetup]: true,
    [EModuleCode.EmployeeServices]: true,
    [EModuleCode.HRAssistant]: true,
    [EModuleCode.PeopleCentral]: true,
    [EModuleCode.SystemConfiguration]: true,
    [EModuleCode.UserManagement]: true,
    [EModuleCode.PayrollSetup]: false,
  },
};
